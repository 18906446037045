import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from '@ers/shared';
import {
  IonSearchbar,
  ModalController,
  InfiniteScrollCustomEvent,
  SearchbarCustomEvent,
} from '@ionic/angular';
import Fuse from 'fuse.js';

@Component({
  selector: 'ers-modal-product-selection',
  templateUrl: './modal-product-selection.component.html',
})
export class ModalProductSelectionComponent implements OnInit {
  @Input() items: Product[] = [];

  @ViewChild('selectSearchbar', { static: false })
  selectSearchbar!: IonSearchbar;

  searchCriteria: string | undefined;
  originalItems: Product[] = [];
  displayedItems: Product[] = [];

  startIndex = 0;
  endIndex = 100;

  constructor(private readonly modalCtrl: ModalController) {}

  ngOnInit() {
    if (this.items.length > 0) {
      this.originalItems = [...this.items];
      this.originalItems.sort((a, b) =>
        a.RSPF_PROD_N.localeCompare(b.RSPF_PROD_N),
      );
    }

    this.search();
  }

  async onItemSelect(product: Product) {
    this.modalCtrl.dismiss(product);
  }

  onIonInfinite(ev: unknown) {
    this.grabMore();
    (ev as InfiniteScrollCustomEvent).target.complete();
  }

  search(event?: Event) {
    this.searchCriteria =
      (<SearchbarCustomEvent>event)?.detail?.value?.toLowerCase() ||
      this.selectSearchbar?.value?.toLowerCase();

    if (this.searchCriteria) {
      const options = {
        includeScore: true,
        isCaseSensitive: false,
        threshold: 0.3,
        ignoreLocation: true,
        keys: ['RSPF_PROD_N'],
      };

      const fuse = new Fuse(this.items, options);

      const searchResults = fuse
        .search(this.searchCriteria)
        .map((foundItem: Fuse.FuseResult<Product>) => foundItem.item);
      this.displayedItems = searchResults.slice(0, 100);
    } else {
      this.startIndex = 0;
      this.endIndex = 100;
      this.displayedItems = this.items.slice(this.startIndex, this.endIndex);
    }
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }

  private grabMore() {
    this.startIndex = this.endIndex;
    this.endIndex = this.endIndex + 100;

    this.displayedItems = [
      ...this.displayedItems,
      ...this.originalItems.slice(this.startIndex, this.endIndex),
    ];
  }
}
