import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ers-cat-app/env/environment';
import { Details, Part, Product, SubmitDpsWorkOrderRequest } from '@ers/shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DpsWorkOrderService {
  private readonly BASE_URL = `${environment.apiUrl}/dps-work-order`;
  constructor(private readonly http: HttpClient) {}

  // valid if sum of all values with type 'L' is greater than or equal to 0.25
  serviceDetailsIsValid = (serviceDetails: Details[]): boolean =>
    serviceDetails
      .filter(item => item.type === 'L')
      .reduce((acc, item) => acc + item.quantity, 0) >= 0.25;

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.BASE_URL}/products`);
  }

  getParts(): Observable<Part[]> {
    return this.http.get<Part[]>(`${this.BASE_URL}/parts`);
  }

  submit(payload: SubmitDpsWorkOrderRequest): Observable<any> {
    return this.http.post(`${this.BASE_URL}/submit`, payload);
  }
}
