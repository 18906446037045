import { AfterContentInit, Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalProductSelectionComponent } from '@ers-cat-app/pages/dps-work-order/modal-product-selection/modal-product-selection.component';

import { SystemService, UserService } from '@ers-cat-app/shared/services';
import { HourMeterService } from '@ers-cat-app/shared/services/services/hour-meter.service';
import { BOOL_LETTER_STRING, Product } from '@ers/shared';
import { ModalController, PopoverController } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '@prisma/client';
import { finalize, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ers-hour-meter-form',
  templateUrl: './hour-meter.component.html',
  styleUrls: ['./hour-meter.component.scss'],
})
export class HourMeterComponent implements AfterContentInit {
  readonly BOOL_LETTER_STRING = BOOL_LETTER_STRING;

  user: User | undefined;

  @Input() allProducts: Product[] = [];
  private _isSubmitting = false;
  private _isLoadingProducts = true;

  hourMeterForm = new FormGroup({
    product: new FormControl<Product | undefined>(undefined, [
      Validators.required,
    ]),
    meter: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(99999999.999999), // 8 digits max
    ]),
  });

  constructor(
    readonly systemService: SystemService,
    private readonly userService: UserService,
    private readonly hourMeterService: HourMeterService,
    private readonly modalCtrl: ModalController,
    private readonly popoverCtrl: PopoverController,
  ) {}

  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  set isSubmitting(value: boolean) {
    this._isSubmitting = value;
  }

  get isLoadingProducts(): boolean {
    return this._isLoadingProducts;
  }

  set isLoadingProducts(value: boolean) {
    this._isLoadingProducts = value;
  }

  get productsAreFiltered(): boolean {
    return this.allProducts.every(
      product => product.IS_METERED === BOOL_LETTER_STRING.Y,
    );
  }

  async openSelectProductModal() {
    const modal = await this.modalCtrl.create({
      component: ModalProductSelectionComponent,
      canDismiss: true,
      componentProps: {
        items: this.allProducts,
      },
    });

    modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      this.hourMeterForm.controls['product'].setValue(data);
      this.hourMeterForm.controls['product'].markAsDirty();

      // Set meter validators to override the default validators and any other previous validators set (i.e. to not add multiple of same validators with different values - particularly for min)
      this.hourMeterForm.controls['meter'].setValidators([
        Validators.required,
        Validators.min(data.LAST_METER),
        Validators.max(99999999.999999),
      ]);
    }
  }

  async ngAfterContentInit() {
    this.user = await this.userService.user$;

    if (this.allProducts.length > 0) {
      this.allProducts = this.filterProducts(this.allProducts);
      this.isLoadingProducts = false;
    } else {
      this.getAllProducts();
    }
  }

  private getAllProducts() {
    this.isLoadingProducts = true;

    this.hourMeterService
      .getProducts()
      .pipe(
        take(1),
        finalize(() => (this.isLoadingProducts = false)),
      )
      .subscribe((products: Product[]) => {
        this.allProducts = this.filterProducts(products);
      });
  }

  private filterProducts(products: Product[]): Product[] {
    return products
      .map((product: Product) => ({ ...product }))
      .filter(product => product.IS_METERED === BOOL_LETTER_STRING.Y);
  }
}
