<ion-header>
  <ion-toolbar>
    <ion-title mode="ios">Product</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <section class="md:max-w-2xl !mx-auto">
    <div class="flex justify-center border-t">
      <div class="w-full">
        <ion-header class="ion-no-border md:max-w-2xl !mx-auto m-0">
          <ion-searchbar
            placeholder="Filter"
            (ionInput)="search($event)"
            [debounce]="500"
            #selectSearchbar></ion-searchbar>
        </ion-header>

        @if(items.length){
        <ion-list lines="full" class="ion-no-padding">
          @for(product of displayedItems; track product){
          <ion-item (click)="onItemSelect(product)" class="cursor-pointer">
            <ion-grid>
              <div class="capitalize font-semibold leading-none m-1">
                {{ product.RSPF_PROD_N }}
                <span class="truncate text-xs text-gray-500 mx-1 align-top">
                  {{ product.RSPF_DESC }}
                </span>
              </div>
            </ion-grid>
          </ion-item>
          }
        </ion-list>
        } @if(displayedItems.length >= 100){
        <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
          <ion-infinite-scroll-content
            loadingText="Please wait..."
            loadingSpinner="bubbles"></ion-infinite-scroll-content>
        </ion-infinite-scroll>
        }
      </div>
    </div>
  </section>
</ion-content>
<ion-footer>
  <section class="m-2 flex justify-around max-w-md !mx-auto">
    <ion-button
      class="custom-secondary mx-2 !w-full"
      color="light"
      (click)="cancel()">
      Cancel
    </ion-button>
  </section>
</ion-footer>
