import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';

import { LoggedInGuard } from './shared/guards/loggedIn.guard';
import { Role } from '@ers/shared';
import { ROUTE_URLS } from './shared/constants';
import { DpsWorkOrderGuard } from './shared/guards/dpsWorkOrder.guard';

const routes: Routes = [
  {
    path: ROUTE_URLS.ADMIN,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    data: {
      authorizedRoles: [
        Role.ADMIN,
        Role.EXECUTIVE,
        Role.ADMIN_USERS,
        Role.ADMIN_ROLES,
        Role.ADMIN_USERS_READ_ONLY,
        Role.ADMIN_SYSTEMS,
      ],
    },

    loadChildren: () =>
      import('./pages/admin/admin.module').then(m => m.AdminPageModule),
  },
  {
    path: ROUTE_URLS.DOCUMENTS,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    data: {
      // authorizedRoles: [Role.DOCUMENTS],
    },
    loadChildren: () =>
      import('./pages/documents/documents.module').then(
        m => m.DocumentsPageModule,
      ),
  },
  {
    path: ROUTE_URLS.ASSET_TRACKING,
    canActivate: [AuthGuard],
    data: {
      authorizedRoles: [Role.ADMIN],
    },
    loadChildren: () =>
      import('./pages/asset-tracking/asset-tracking.module').then(
        m => m.AssetTrackingPageModule,
      ),
  },
  {
    path: ROUTE_URLS.DPS_WORK_ORDER,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dps-work-order/dps-work-order.module').then(
        m => m.DpsWorkOrderPageModule,
      ),
  },
  {
    path: ROUTE_URLS.EQUIPMENT,
    canActivate: [DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/equipment/equipment.module').then(
        m => m.EquipmentPageModule,
      ),
  },
  {
    path: ROUTE_URLS.JOB_MONITORING,
    canActivate: [AuthGuard],
    data: {
      authorizedRoles: [Role.ADMIN],
    },
    loadChildren: () =>
      import('./pages/job-monitoring/job-monitoring.module').then(
        m => m.JobMonitoringPageModule,
      ),
  },
  {
    path: ROUTE_URLS.FILE_SHARE,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/file-share/file-share.module').then(
        m => m.FileSharePageModule,
      ),
  },
  {
    path: ROUTE_URLS.EQUIPMENT_DETAILS,
    canActivate: [DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/equipment-details/equipment-details.module').then(
        m => m.EquipmentDetailsPageModule,
      ),
  },
  {
    path: ROUTE_URLS.GIVE_FEEDBACK,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then(m => m.FeedbackModule),
  },
  {
    path: ROUTE_URLS.FORGOT_PASSWORD,
    canActivate: [LoggedInGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordPageModule,
      ),
  },
  {
    path: ROUTE_URLS.RESET_PASSWORD,
    canActivate: [LoggedInGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        m => m.ResetPasswordPageModule,
      ),
  },
  {
    path: ROUTE_URLS.HOME,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: ROUTE_URLS.LANDING,
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./pages/landing/landing.module').then(m => m.LandingPageModule),
  },
  {
    path: ROUTE_URLS.LOGIN,
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: ROUTE_URLS.BUSINESS_CARD,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/business-card/business-card.module').then(
        m => m.BusinessCardPageModule,
      ),
  },
  {
    path: ROUTE_URLS.CREATE_ACCOUNT,
    canActivate: [LoggedInGuard, DpsWorkOrderGuard],
    loadChildren: () =>
      import('./pages/create-account/create-account.module').then(
        m => m.CreateAccountPageModule,
      ),
  },
  {
    path: ROUTE_URLS.SAFETY,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/safety/safety.module').then(m => m.SafetyPageModule),
  },
  {
    path: ROUTE_URLS.SALES_HUB,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    data: {
      authorizedRoles: [
        Role.SALES_REP,
        Role.EXECUTIVE,
        Role.MANAGER,
        Role.ADMIN,
        Role.SALES_HUB,
        Role.SALES_HUB_ADMIN,
        Role.SALES_HUB_MANAGER,
      ],
    },
    loadChildren: () =>
      import('./pages/sales-hub/sales-hub.module').then(
        m => m.SalesHubPageModule,
      ),
  },
  {
    path: ROUTE_URLS.TOOLS,
    canActivate: [AuthGuard, DpsWorkOrderGuard],
    data: {
      // authorizedRoles: [
      //   Role.CALCULATORS,
      //   Role.ON_DEMAND_DOCS,
      //   Role.SAFETY_INCIDENT_REPORT,
      //   Role.OFF_RENT_REQUEST,
      // ],
    },
    loadChildren: () =>
      import('./pages/tools/tools.module').then(m => m.ToolsPageModule),
  },
  {
    path: ROUTE_URLS.SETTINGS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: ROUTE_URLS.AUTH_REDIRECT,
    loadChildren: () =>
      import('./pages/auth-redirect/auth-redirect.module').then(
        m => m.AuthRedirectPageModule,
      ),
  },
  {
    path: ROUTE_URLS.SITE_MAP,
    loadChildren: () =>
      import('./pages/sitemap/sitemap.module').then(m => m.SiteMapModule),
  },
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },

  // Reroute all nonexisting routes
  { path: '**', redirectTo: ROUTE_URLS.LANDING },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
