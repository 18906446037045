<ion-app>
  @if((networkService.isOnline$ | async) === false){
  <ion-header class="py-1 offline-header">
    <section>
      <ion-row class="mx-1 flex justify-center">
        <ion-icon class="!text-black mx-1" name="alert-circle"></ion-icon>
        <span
          class="text-center text-black text-sm ion-text-uppercase font-bold">
          You are in Offline Mode
        </span>
      </ion-row>
      <ion-row class="mx-1 flex justify-center">
        <span class="text-center text-black font-bold text-xs">
          Data was last refreshed on
          {{ equipmentService.equipmentCacheLastUpdated }}
        </span>
      </ion-row>
    </section>
  </ion-header>
  }
  <ion-content>
    <ion-split-pane contentId="main-content" when="lg">
      <ion-menu
        contentId="main-content"
        class="border-r lg:w-14"
        type="overlay"
        [ngClass]="{
          'md:hidden': !isLoggedIn,
          hidden: hideMenu,
        }">
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <ion-grid [fixed]="true" class="mb-4">
              <ion-row>
                <ion-col class="flex justify-center">
                  <img
                    [src]="
                      systemService.isDarkMode
                        ? ASSET_LOCATIONS.POWER_PLUS_LOGO_WHITE_SVG
                        : ASSET_LOCATIONS.POWER_PLUS_LOGO_PNG
                    "
                    alt=""
                    class="w-10/12 max-w-xs" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="flex justify-center">
                  <img
                    [src]="
                      systemService.isDarkMode
                        ? ASSET_LOCATIONS.LANDING_TAGLINE_WHITE_SVG
                        : ASSET_LOCATIONS.LANDING_TAGLINE_SVG
                    "
                    alt=""
                    class="w-10/12 max-w-xs" />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list lines="full">
            @if(authService.isLoggedIn$ | async){
            <ion-menu-toggle auto-hide="false">
              @for(p of primaryLinks; track p.url){ @if(p.show){
              <ion-item
                (click)="navigate(p.url, p.args)"
                routerDirection="root"
                detail="false"
                class="group"
                [ngClass]="{ selected: isSelected(p),
                   'dark-mode': systemService.isDarkMode,
                }">
                <span>
                  @if(p.iconLocation){
                  <!-- invert class id used to turn icon from black to white for dark mode -->
                  <img
                    class="w-5 mr-4"
                    [src]="p.iconLocation"
                    slot="start"
                    alt="icon" />
                  } @else {
                  <ion-icon
                    class="mr-4 group-hover:stroke-red"
                    [name]="p.icon"
                    slot="start"
                    color="black"></ion-icon>
                  }
                </span>
                <ion-label class="font-semibold">
                  {{ p.title }}
                </ion-label>
                <ion-icon name="caret-forward" slot="end"></ion-icon>
              </ion-item>
              } }
            </ion-menu-toggle>
            }
          </ion-list>
        </ion-content>
        <ion-footer class="ion-no-border !px-0 !py-1">
          <ion-grid class="mb-0 pb-0">
            <!-- Give Feedback -->
            <ion-row class="-mx-7 mt-auto">
              <ion-col class="pb-0">
                <a
                  [routerLink]="ROUTE_URLS.GIVE_FEEDBACK"
                  class="flex items-center gap-x-2 px-8 py-3 leading-6 text-gray-900">
                  <ion-icon
                    name="mail"
                    class="icon-lg h-8 w-8 rounded-full"></ion-icon>

                  <span>Give Feedback</span>
                </a>
              </ion-col>
            </ion-row>

            <!-- Settings / Profile -->
            <ion-row class="-mx-7 mt-auto">
              <ion-col class="pb-0">
                <a
                  [routerLink]="ROUTE_URLS.SETTINGS"
                  class="flex items-center gap-x-2 px-8 py-3 font-semibold leading-6 text-gray-900">
                  @if(objectUrl){
                  <ion-img [src]="objectUrl" class="icon-xl h-8 w-8"></ion-img>
                  } @else{
                  <ion-icon
                    name="person-circle"
                    class="icon-lg h-8 w-8 rounded-full"></ion-icon>
                  }
                  <span class="sr-only">Your profile</span>
                  <span aria-hidden="true">
                    @if(this.user){
                    {{ user.firstName }} {{ user.lastName }}
                    } @else {
                    <ion-label class="!flex justify-center mx-1">
                      <ion-spinner name="dots"></ion-spinner>
                    </ion-label>
                    }
                  </span>
                </a>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-footer>
      </ion-menu>

      <ion-router-outlet
        id="main-content"
        (ionNavWillChange)="closeMenu()"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
</ion-app>
<ers-app-insights></ers-app-insights>
<app-redirect></app-redirect>
