import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HourMeterComponent } from './components/hour-meter/hour-meter.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoImgComponent } from './components/logo-img/logo-img.component';

@NgModule({
  declarations: [HourMeterComponent],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  exports: [HourMeterComponent],
})
export class SharedModule {}
