import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ers-cat-app/env/environment';
import { DpsWorkOrderService } from '@ers-cat-app/pages/dps-work-order/services/dps-work-order.service';
import { HourMeterRequest, Product } from '@ers/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HourMeterService {
  private readonly BASE_URL = `${environment.apiUrl}/hour-meter`;
  constructor(
    private readonly http: HttpClient,
    private readonly dpsWorkOrderService: DpsWorkOrderService,
  ) {}

  getProducts(): Observable<Product[]> {
    // filter on UI side for IS_METERED === 'Y'
    return this.dpsWorkOrderService.getProducts();
  }

  submit(data: {
    payload: HourMeterRequest;
    productNumber: string;
  }): Observable<any> {
    return this.http.put(`${this.BASE_URL}/submit`, data);
  }
}
