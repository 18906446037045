export const ASSET_LOCATIONS = {
  POWER_PLUS_LOGO_PNG: './assets/images/power-plus.png',
  POWER_PLUS_LOGO_WHITE_SVG: './assets/images/power-plus-white.svg',
  BUSINESS_CARD_CALENDAR_SVG: './assets/images/business-cards/calendar.svg',
  BUSINESS_CARD_VCARD_SVG: './assets/images/business-cards/v-card.svg',
  DPS_LOGO_PNG: './assets/images/dps/DPS-Logo.png',
  ERS_LOGO_SVG: './assets/images/ers-logo.svg',
  LANDING_TAGLINE_SVG: './assets/images/landing-tagline.svg',
  LANDING_TAGLINE_WHITE_SVG: './assets/images/landing-tagline-white.svg',
  ERS_POWER_PLUS_DYNAMIC_SVG: './assets/images/ERS_Power+_dynamic.svg',
  TRAILER_EQUIPMENT_PNG: './assets/images/equipment.png',
  TURBINE_EQUIPMENT_PNG: './assets/images/dps/SMT60-Eq.png',
  LANDING_WELCOME_PNG: './assets/images/landing-welcome.png',
  LANDING_WELCOME_WHITE_PNG: './assets/images/landing-welcome-white.png',
  DPS_JOB_MONITORING_WEBM: './assets/videos/dps/DPS_PYE_Group-2.webm',
  DPS_JOB_MONITORING_MP4: './assets/videos/dps/DPS_PYE_Group-2.mp4',
  SAFETY_HELMET_SVG: './assets/icons/user-helmet-safety-solid.svg',
};
