import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { withCache } from '@ngneat/cashew';

import { environment } from '@ers-cat-app/env/environment';
import {
  EQUIPMENT_LIST,
  Equipment,
  Product,
  ProductWorkOrder,
} from '@ers-cat-app/shared/models';
import {
  EquipmentAnalysisGroup,
  EquipmentClassGroup,
  EquipmentItem,
} from '@ers-cat-app/pages/equipment/interfaces/equipment-menu.interface';
import { STORAGE_KEYS } from '@ers-cat-app/shared/constants/storage-keys';
import { removeWordsFromFront } from '@ers-cat-app/shared/functions/string-transformations';
import {
  COMPANY,
  EquipmentDocument,
  EquipmentProduct,
  EquipmentProductFileFound,
  File,
} from '@ers/shared';
import { DomainService } from '../domain';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  equipmentCacheLastUpdated = new Date(0, 0, 0).toLocaleString();
  private readonly BASE_URL = `${environment.apiUrl}/equipment`;
  private readonly BASE_URL_PUBLIC = `${environment.apiUrl}/public/equipment`;

  constructor(
    private readonly http: HttpClient,
    private readonly domainService: DomainService,
  ) {}

  list(): Observable<Equipment[]> {
    return of(EQUIPMENT_LIST);
  }

  get(id: number, companyCode?: string): Observable<EquipmentItem> {
    let params = new HttpParams();

    if (companyCode) {
      params = params.append('companyCode', companyCode);
    }

    return this.http.get<EquipmentItem>(`${this.BASE_URL}/fetch/${id}`, {
      params,
    });
  }

  getMenu(): Observable<EquipmentAnalysisGroup[]> {
    this.equipmentCacheLastUpdated = new Date().toLocaleString();
    return this.http.get<EquipmentAnalysisGroup[]>(`${this.BASE_URL}/menu`, {
      context: withCache(),
    });
  }

  getCachedMenu(): EquipmentAnalysisGroup[] {
    const cacheJsonString =
      localStorage.getItem(STORAGE_KEYS.EQUIPMENT_CACHE) || '[]';

    return JSON.parse(cacheJsonString).body;
  }

  getCachedMenuMapped(): EquipmentAnalysisGroup[] {
    const parsedCache = this.getCachedMenu();
    const sorted = this.sortList(parsedCache, 1);
    return sorted as EquipmentAnalysisGroup[];
  }

  getCachedMenuItemsOnly(): EquipmentItem[] {
    const cache = this.getCachedMenuMapped();
    const currentDisplayedDomainCompanyCode =
      this.domainService.currentDisplayedDomainCompanyCode;

    const flattenedCacheItemsOnly: EquipmentItem[] = cache
      .map((x: EquipmentAnalysisGroup) => x.children)
      .reduce((acc, val) => acc.concat(val), []) // .flat()
      .map((x: EquipmentClassGroup) => x.children)
      .reduce((acc, val) => acc.concat(val), []); // .flat();
    const filteredByCompany = flattenedCacheItemsOnly.filter(
      (x: EquipmentItem) => x.companyCode === currentDisplayedDomainCompanyCode,
    );

    return filteredByCompany;
  }

  getAnalysisMenu(
    companyCode: string,
    id?: number,
  ): Observable<EquipmentAnalysisGroup[]> {
    let params = new HttpParams().append('companyCode', companyCode);

    // must reassign params to add id and have companyCode persist
    if (id) {
      params = params.append('id', id);
    }

    return this.http.get<EquipmentAnalysisGroup[]>(
      `${this.BASE_URL}/menu/analysis`,
      {
        params,
      },
    );
  }

  getClassMenu(companyCode: string, id: number): Observable<EquipmentItem[]> {
    const params = new HttpParams()
      .append('id', id)
      .append('companyCode', companyCode);
    return this.http.get<EquipmentItem[]>(`${this.BASE_URL}/menu/class`, {
      params,
    });
  }

  sortList(
    list: EquipmentAnalysisGroup[] | EquipmentClassGroup[] | EquipmentItem[],
    firstWordsToIgnore = 0,
  ) {
    return (
      list?.sort(
        (
          a: EquipmentAnalysisGroup | EquipmentClassGroup | EquipmentItem,
          b: EquipmentAnalysisGroup | EquipmentClassGroup | EquipmentItem,
        ) => {
          const textA = removeWordsFromFront(
            (a.name || '').toLowerCase(),
            firstWordsToIgnore,
          );
          const textB = removeWordsFromFront(
            (b.name || '').toLowerCase(),
            firstWordsToIgnore,
          );
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        },
      ) || []
    );
  }

  getFileByName(fileName: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/download/${fileName}`, {
      headers: { Accept: 'application/octet-stream' },
      responseType: 'blob' as 'json',
    });
  }

  getFileInfoByName(fileName: string, itemId?: string): Observable<File> {
    return itemId
      ? this.getFileInfoById(itemId)
      : this.http.get<File>(`${this.BASE_URL}/download-link/${fileName}`);
  }

  getFileInfoById(fileId: string): Observable<File> {
    return this.http.get<File>(`${this.BASE_URL}/download-id/${fileId}`);
  }

  getFileNamesByGroup(groupNumber: string): Observable<EquipmentDocument[]> {
    return this.http.get<EquipmentDocument[]>(
      `${this.BASE_URL}/documents/${groupNumber}`,
    );
  }

  getProductWorkOrdersList(
    productNumber: string,
    company: 'ers' | 'dps',
    orderStatus: 'open' | 'closed' | 'all',
    limit: number,
  ): Observable<ProductWorkOrder[]> {
    return this.http.get<ProductWorkOrder[]>(
      `${this.BASE_URL}/product/work-orders-list`,
      {
        params: {
          productNumber,
          company,
          orderStatus,
          limit,
        },
      },
    );
  }

  getProductWorkOrder(
    company: 'ers' | 'dps',
    // docType: 'workorder' | 'contract' | 'invoice',
    docNumber: string,
  ) {
    return this.http.get(`${this.BASE_URL}/product/work-order`, {
      responseType: 'text',
      params: {
        company,
        // docType,
        docNumber,
      },
    });
  }

  updateEquipment(
    id: number,
    equipment: Partial<EquipmentItem>,
  ): Observable<{ success: boolean }> {
    return this.http.patch<{ success: boolean }>(
      `${this.BASE_URL}/update/${id}`,
      equipment,
    );
  }

  fetchProductsInGroup(groupNumber: string) {
    return this.http.get<Product[]>(
      `${this.BASE_URL}/products/groups/${groupNumber}`,
    );
  }

  // ----------------- Public ----------------- //

  getPublic(
    id: number,
    companyCode?: keyof typeof COMPANY,
  ): Observable<EquipmentItem> {
    return this.http.get<EquipmentItem>(`${this.BASE_URL_PUBLIC}/fetch/${id}`, {
      params: { companyCode: companyCode as string },
    });
  }

  getProductPublic(id: string): Observable<any> {
    return this.http.get<any>(`${this.BASE_URL_PUBLIC}/fetch/product/${id}`, {
      responseType: 'json',
    });
  }

  getMenuPublic(): Observable<EquipmentAnalysisGroup[]> {
    this.equipmentCacheLastUpdated = new Date().toLocaleString();
    return this.http.get<EquipmentAnalysisGroup[]>(
      `${this.BASE_URL_PUBLIC}/menu`,
      {
        context: withCache(),
      },
    );
  }

  getAnalysisMenuPublic(id?: number): Observable<EquipmentAnalysisGroup[]> {
    if (id) {
      const params = new HttpParams().append('id', id);
      return this.http.get<EquipmentAnalysisGroup[]>(
        `${this.BASE_URL_PUBLIC}/menu/analysis`,
        {
          params,
        },
      );
    }
    return this.http.get<EquipmentAnalysisGroup[]>(
      `${this.BASE_URL_PUBLIC}/menu/analysis`,
    );
  }

  getClassMenuPublic(id: number): Observable<EquipmentItem[]> {
    const params = new HttpParams().append('id', id);

    return this.http.get<EquipmentItem[]>(
      `${this.BASE_URL_PUBLIC}/menu/class`,
      {
        params,
      },
    );
  }

  // sortListUnauth(
  //   list: EquipmentAnalysisGroup[] | EquipmentClassGroup[] | EquipmentItem[],
  //   firstWordsToIgnore = 0,
  // ) {
  //   return (
  //     list?.sort(
  //       (
  //         a: EquipmentAnalysisGroup | EquipmentClassGroup | EquipmentItem,
  //         b: EquipmentAnalysisGroup | EquipmentClassGroup | EquipmentItem,
  //       ) => {
  //         const textA = removeWordsFromFront(
  //           (a.name || '').toLowerCase(),
  //           firstWordsToIgnore,
  //         );
  //         const textB = removeWordsFromFront(
  //           (b.name || '').toLowerCase(),
  //           firstWordsToIgnore,
  //         );
  //         return textA < textB ? -1 : textA > textB ? 1 : 0;
  //       },
  //     ) || []
  //   );
  // }

  // getFileByNameUnauth(fileName: string): Observable<any> {
  //   return this.http.get(`${this.BASE_URL_UNAUTH}/download/${fileName}`, {
  //     headers: { Accept: 'application/octet-stream' },
  //     responseType: 'blob' as 'json',
  //   });
  // }

  // getFileInfoByNameUnauth(fileName: string, itemId?: string): Observable<File> {
  //   return itemId
  //     ? this.getFileInfoById(itemId)
  //     : this.http.get<File>(
  //         `${this.BASE_URL_UNAUTH}/download-link/${fileName}`,
  //       );
  // }

  // getFileInfoByIdUnauth(fileId: string): Observable<File> {
  //   return this.http.get<File>(`${this.BASE_URL_UNAUTH}/download-id/${fileId}`);
  // }

  getFileNamesByGroupPublic(
    equipmentId: string,
  ): Observable<EquipmentDocument[]> {
    return this.http.get<EquipmentDocument[]>(
      `${this.BASE_URL_PUBLIC}/documents/${equipmentId}`,
    );
  }

  fetchProductsInGroupPublic(groupNumber: string) {
    return this.http.get<Product[]>(
      `${this.BASE_URL_PUBLIC}/products/groups/${groupNumber}`,
    );
  }

  fetchProductDocumentsPublic(
    docIds: string[],
  ): Observable<EquipmentProductFileFound> {
    return this.http.get<EquipmentProductFileFound>(
      `${this.BASE_URL_PUBLIC}/documents`,
      {
        params: {
          docIds,
        },
      },
    );
  }

  fetchProductImagesPublic(
    ids: string[],
  ): Observable<EquipmentProductFileFound> {
    return this.http.get<EquipmentProductFileFound>(
      `${this.BASE_URL_PUBLIC}/images`,
      {
        params: {
          ids,
        },
      },
    );
  }
}
