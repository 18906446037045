export const ROUTE_URLS = {
  ADMIN: 'admin',
  ADMIN_USERS_LIST: 'admin/users-list',
  ADMIN_ROLE_LIST: 'admin/roles-list',
  ADMIN_USER_MANAGE: 'admin/user-manage',
  ADMIN_ROLE_MANAGE: 'admin/role-manage',
  ADMIN_SYSTEM: 'admin/system',
  ASSET_TRACKING: 'asset-tracking',
  BUSINESS_CARD: 'business-card',
  CREATE_ACCOUNT: 'create-account',
  DOCUMENTS: 'documents',
  BROCHURES_AND_FLYERS: 'documents/brochures-and-flyers',
  DOCUMENT_PAGE: 'documents/document',
  DPS_WORK_ORDER: 'dps-work-order',

  EQUIPMENT: 'equipment',
  EQUIPMENT_ANALYSIS: 'equipment/analysis',
  EQUIPMENT_CLASS: 'equipment/class',
  EQUIPMENT_DETAILS: 'equipment-details',
  EQUIPMENT_DETAILS_PUBLIC_LANDING: 'equipment-details/public/landing',
  EQUIPMENT_DETAILS_PUBLIC: 'equipment-details/public',

  FILE_SHARE: 'file-share',
  FORGOT_PASSWORD: 'forgot-password',
  HOME: 'home',

  JOB_MONITORING: 'job-monitoring',
  JOB: 'job-monitoring/job',

  LANDING: 'landing',
  LOGIN: 'login',

  // Safety
  SAFETY: 'safety',
  SAFETY_SAFETY_INCIDENT_REPORT: 'safety/safety-incident-report',
  SAFETY_JOB_SAFETY_ANALYSIS: 'safety/jsa',
  SAFETY_CONTACT_HSE: 'safety/contact-hse',

  // Sales Hub
  SALES_HUB: 'sales-hub',

  // Tools
  TOOLS: 'tools',
  TOOLS_CALCULATORS: 'tools/calculators',
  TOOLS_CALCULATOR_COOLING: 'tools/calculators/cooling',
  TOOLS_CALCULATOR_COOLING_REPORT: 'tools/calculators/cooling/report',
  TOOLS_CALCULATOR_COOLING_REPORT_DETAILS:
    'tools/calculators/cooling/report-details',
  TOOLS_CALCULATOR_DPS_AMP: 'tools/calculators/dps/amps',
  TOOLS_CALCULATOR_DPS_EFFICIENCY: 'tools/calculators/dps/efficiency',
  TOOLS_CALCULATOR_DPS_FLOW_RATE: 'tools/calculators/dps/flow-rate',
  TOOLS_CALCULATOR_DPS_FUEL_CONSUMPTION:
    'tools/calculators/dps/fuel-consumption',
  TOOLS_CALCULATOR_DPS_FUEL_COST: 'tools/calculators/dps/fuel-cost',
  TOOLS_CALCULATOR_DPS_HP_KW: 'tools/calculators/dps/hp-kw',
  TOOLS_CALCULATOR_DPS_KJ_BTU: 'tools/calculators/dps/kj-btu',
  TOOLS_CALCULATOR_DPS_KVA: 'tools/calculators/dps/kva',
  TOOLS_CALCULATOR_DPS_KW: 'tools/calculators/dps/kw',
  TOOLS_CALCULATOR_DPS_KW_HOUR_COST: 'tools/calculators/dps/kw-hour-cost',
  TOOLS_CALCULATOR_DPS_MCF_CUBIC_HOUR: 'tools/calculators/dps/mcf-cubic-hour',
  TOOLS_CALCULATOR_DPS_PROJECT_SIZING_ISO:
    'tools/calculators/dps/project-sizing-iso',
  TOOLS_CALCULATOR_DPS_PROJECT_SIZING_SITE_RATING:
    'tools/calculators/dps/project-sizing-site-rating',

  TOOLS_CALCULATOR_CONVERSION: 'tools/calculators/conversion',
  TOOLS_ON_DEMAND_DOCS: 'tools/on-demand-docs',
  TOOLS_ON_DEMAND_DOCS_INVOICE: 'tools/on-demand-docs/invoice',
  TOOLS_ON_DEMAND_DOCS_CONTRACT: 'tools/on-demand-docs/contract',

  TOOLS_OFF_RENT_REQUESTS: 'tools/off-rent-requests',
  TOOLS_OFF_RENT_REQUESTS_CONTRACT: 'tools/off-rent-requests/contract',

  TOOLS_PICK_ORDER: 'tools/pick-order',
  TOOLS_UPLIFT: 'tools/uplift',
  TOOLS_UPLIFT_ITEMS_SELECTION: 'tools/uplift-items-selection',

  TOOLS_HOUR_METER: 'tools/hour-meter',

  RESET_PASSWORD: 'reset-password',

  SETTINGS: 'settings',
  SETTINGS_CHANGE_PASSWORD: 'settings/change-password',
  AUTH_REDIRECT: 'auth-redirect',
  SETTINGS_HEALTH: 'settings/health',

  GIVE_FEEDBACK: 'feedback',
  SITE_MAP: 'sitemap',
};
