export interface EquipmentProduct {
  analysisGroup: string;
  capacity: number;
  classDescription: string;
  classNumber: number;
  companyCode: string;
  docIds: number[];
  groupDescription: string;
  groupNumber: string;
  keyData: { KEY_DATA_LABEL: string; KEY_DATA_VALUE: string }[];
  productNumber: string;
  unitOfMeasure: string;
  imgId: string;
  pmParts: PmPart[];
}

export interface PmPart {
  productNumber: string;
  productDescription: string;
  requiredQty: number;
}
